<script>
import { mapState } from 'vuex';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  name: 'PriorityIcon',
  components: { Icon },
  props: {
    priority: {
      type: String,
      default: 'priorityLow',
      required: false,
    },
    defaultSize: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
    classObject() {
      return [this.getPriorityIconColor(), this.defaultSize ? 'h-6 w-6' : ''];
    },
  },
  methods: {
    getPriorityIconColor() {
      switch (this.priority) {
        case 'priorityLow': {
          return 'bg-neutral-3';
        }
        case 'priorityMedium': {
          return 'bg-warning bg-opacity-10';
        }
        case 'priorityHigh': {
          return 'bg-chevron-red bg-opacity-10';
        }
      }
    },
  },
});
</script>

<template>
  <div class="flex flex-col justify-center rounded-full" :class="classObject">
    <Icon
      :accent="color"
      :dark-mode="darkMode"
      :name="priority"
      class="place-self-center"
    />
  </div>
</template>
